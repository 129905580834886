@tailwind base;
@tailwind components;

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #23355f;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.toggle-checkbox:checked {
  @apply: right-0 border-gray-700;
  right: 0;
}

.toggle-checkbox:checked + .toggle-label {
  @apply: bg-gray-400;
}

.react-datepicker__header {
  text-align: center;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
  font-weight: bold;
  background-color: white;
  border-bottom: 0;
}

.react-datepicker-time__input {
  border-radius: 0.3rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgb(55, 65, 81);
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: rgb(55, 65, 81);
  color: #fff;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: rgb(55, 65, 81);
}

.react-datepicker-wrapper {
  width: 100%;
}

.dark {
  color: rgb(229, 231, 235) !important;
}

.rc-pagination {
  font-size: 12px;
  font-family: 'Arial';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;

  margin-bottom: 0;
}
.rc-pagination > li {
  list-style: none;
}
.rc-pagination-total-text {
  float: left;
  height: 30px;
  line-height: 30px;
  list-style: none;
  padding: 0;
  margin: 0 8px 0 0;
  font-size: 14px;
}
.rc-pagination:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.rc-pagination-item {
  cursor: pointer;
  border-radius: 6px;
  min-width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  list-style: none;
  float: left;
  border: 1px solid rgb(229, 231, 235);
  background-color: transparent;
  margin-right: 8px;
}

.dark .rc-pagination-item {
  border: 1px solid rgba(75, 85, 99) !important;
}

.rc-pagination-item a {
  text-decoration: none;
}
.rc-pagination-item:hover {
  border-color: rgb(229, 231, 235);
}
.rc-pagination-item:hover a {
}
.rc-pagination-item-disabled {
  cursor: not-allowed;
}
.rc-pagination-item-disabled:hover {
  border-color: rgb(229, 231, 235);
}
.rc-pagination-item-disabled:hover a {
}
.rc-pagination-item-active {
  background-color: rgb(17, 24, 39);
  color: rgb(229, 231, 235);
  border-color: rgb(229, 231, 235);
}
.rc-pagination-item-active a {
  background-color: rgb(17, 24, 39);
}
.rc-pagination-item-active:hover a {
  background-color: rgb(17, 24, 39);
}
.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  content: '•••';
  display: block;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 1px;
}
.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
}
.rc-pagination-jump-prev:hover:after {
  content: '«';
}
.rc-pagination-jump-next:hover:after {
  content: '»';
}
.rc-pagination-jump-prev-custom-icon,
.rc-pagination-jump-next-custom-icon {
  position: relative;
}
.rc-pagination-jump-prev-custom-icon:after,
.rc-pagination-jump-next-custom-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transition: all 0.2s;
  content: '•••';
  opacity: 1;
  display: block;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 1px;
}
.rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon
  .custom-icon-jump-next {
  opacity: 0;
  transition: all 0.2s;
}
.rc-pagination-jump-prev-custom-icon:hover:after,
.rc-pagination-jump-next-custom-icon:hover:after {
  opacity: 0;
}
.rc-pagination-jump-prev-custom-icon:hover
  .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover
  .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover
  .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover
  .custom-icon-jump-next {
  opacity: 1;
}
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  cursor: pointer;
  font-size: 10px;
  border-radius: 6px;
  list-style: none;
  min-width: 31px;
  height: 31px;
  line-height: 31px;
  float: left;
  text-align: center;
}
.rc-pagination-prev a:after {
  content: '‹';
  display: block;
}
.rc-pagination-next a:after {
  content: '›';
  display: block;
}
.rc-pagination-prev,
.rc-pagination-next {
  border: 1px solid rgb(229, 231, 235);
  font-size: 18px;
}

.dark .rc-pagination-prev,
.dark .rc-pagination-next {
  border: 1px solid rgba(75, 85, 99);
  font-size: 18px;
}

.rc-pagination-prev a,
.rc-pagination-next a {
}
.rc-pagination-prev a:after,
.rc-pagination-next a:after {
  margin-top: -1px;
}
.rc-pagination-disabled {
  cursor: not-allowed;
}
.rc-pagination-disabled a {
}
.rc-pagination-disabled .rc-pagination-item,
.rc-pagination-disabled .rc-pagination-prev,
.rc-pagination-disabled .rc-pagination-next {
  cursor: not-allowed;
}
.rc-pagination-disabled .rc-pagination-item:hover,
.rc-pagination-disabled .rc-pagination-prev:hover,
.rc-pagination-disabled .rc-pagination-next:hover {
  border-color: rgb(229, 231, 235);
}
.rc-pagination-disabled .rc-pagination-item:hover a,
.rc-pagination-disabled .rc-pagination-prev:hover a,
.rc-pagination-disabled .rc-pagination-next:hover a {
}
.rc-pagination-disabled .rc-pagination-jump-prev,
.rc-pagination-disabled .rc-pagination-jump-next {
  pointer-events: none;
}
.rc-pagination-options {
  float: left;
  margin-left: 15px;
}
.rc-pagination-options-size-changer {
  float: left;
  width: 80px;
}
.rc-pagination-options-quick-jumper {
  float: left;
  margin-left: 16px;
  height: 31px;
  line-height: 31px;
}
.rc-pagination-options-quick-jumper input {
  margin: 0 8px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgb(229, 231, 235);
  outline: none;
  padding: 3px 12px;
  width: 50px;
  height: 31px;
}
.rc-pagination-options-quick-jumper input:hover {
  border-color: rgb(229, 231, 235);
}
.rc-pagination-options-quick-jumper button {
  display: inline-block;
  margin: 0 8px;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid rgb(229, 231, 235);
  white-space: nowrap;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 6px;
  height: 31px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}
.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
  background-color: rgb(17, 24, 39);
  border-color: rgb(229, 231, 235);
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  border: none;
  height: 24px;
  line-height: 24px;
  margin: 0;
  font-size: 18px;
}
.rc-pagination-simple .rc-pagination-simple-pager {
  float: left;
  margin-right: 8px;
  list-style: none;
}
.rc-pagination-simple
  .rc-pagination-simple-pager
  .rc-pagination-slash {
  margin: 0 10px;
}
.rc-pagination-simple .rc-pagination-simple-pager input {
  margin: 0 8px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid rgb(229, 231, 235);
  outline: none;
  padding: 5px 8px;
  min-height: 20px;
}
.rc-pagination-simple
  .rc-pagination-simple-pager
  input:hover {
  border-color: rgb(229, 231, 235);
}
.rc-pagination-simple .rc-pagination-simple-pager button {
  display: inline-block;
  margin: 0 8px;
  font-weight: 500;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 6px;
  height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}
.rc-pagination-simple
  .rc-pagination-simple-pager
  button:hover,
.rc-pagination-simple
  .rc-pagination-simple-pager
  button:active,
.rc-pagination-simple
  .rc-pagination-simple-pager
  button:focus {
  background-color: transparent;
  border-color: rgb(229, 231, 235);
}
@media only screen and (max-width: 1024px) {
  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}

@tailwind utilities;

.w-md {
  width: 28rem;
}

@media (min-width: 640px) {
  .sm\:w-md {
    width: 28rem;
  }
}

@media (min-width: 768px) {
  .md\:w-md {
    width: 28rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-md {
    width: 28rem;
  }
}
@media (max-width: 320px) {
.profile-head{
  display: block !important;
}
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* add custom */
[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  --tw-ring-inset: 0;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: transparent;
  --tw-ring-color: transparent;
  border-color: #d1d5db;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #DBDBDB; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DBDBDB; 
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
 .course-table{
  width: 100%;
}
.course-table>tbody>tr>td:first-child{
  color: rgba(59, 130, 246);;
}

.rotatee{
    transform: rotate(270deg);
}